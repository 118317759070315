import { Dispatch } from 'react';
import getConfig from 'next/config';
import { ContentResource, PlayerApi, PlayerConfig, PlayerEvent, UiConfig } from '@p7s1/oasis-types';
import createOasisPlayer from '@p7s1/oasis-player';
import { somMarketingDefault } from '@src/utils/somtag';
import createUpdatePosition from '@src/components/OasisPlayer/positon-update';
import { trackMedia } from '@src/utils/et';
import { Action, State } from './OasisPlayerStore';

export interface PlayerInstance {
  playerApi: PlayerApi | null;
  videoDomElement: HTMLElement | null;
}

export const initPlayerWithConfig = (container: HTMLElement) => {
  const { publicRuntimeConfig } = getConfig();
  const id = publicRuntimeConfig.OASIS_CONFIG_ID;
  const element = document.createElement('div');
  element.id = 'video-wrapper';
  element.className = 'video-wrapper';
  container.appendChild(element);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ui: Partial<UiConfig> = {
    enableCloseButton: true,
  } as Partial<UiConfig>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const playerConfig: PlayerConfig = ({
    id,
    element,
    ...ui,
  } as unknown) as PlayerConfig;
  return createOasisPlayer(playerConfig).then(api => ({ api, element }));
};

// helper method for detaching video dom element
// eslint-disable-next-line
export const detachNode = (node: HTMLElement | Element) => {
  if (node && node.parentElement) {
    return node.parentElement.removeChild(node);
  }
};

export const getContainerRef = (activePlayer: string, isInView: boolean) => {
  const defaultContainer = activePlayer
    ? document.getElementById(`video-frame-${activePlayer}`)
    : null;
  return activePlayer && !isInView
    ? document.getElementById('video-frame-sticky')
  : defaultContainer;
};

// Main state handling logic
export const updatePlayerInstance = async (
  state: State,
  dispatch: Dispatch<Action>,
  prevState: State,
  playerInstance: PlayerInstance,
) => {
  let currentPlayerInstance = playerInstance;
  const container = getContainerRef(state.activePlayer, state.isInView);

  if (
    prevState.activePlayer !== state.activePlayer &&
    currentPlayerInstance.playerApi &&
    currentPlayerInstance.videoDomElement
  ) {
    // if player ceases to be active
    // destroy existing DOM reference
    detachNode(currentPlayerInstance.videoDomElement);
    // reset player instance
    currentPlayerInstance = {
      playerApi: null,
      videoDomElement: null,
    };
  }

  if (
    state.activePlayer &&
    prevState.activePlayer !== state.activePlayer &&
    state.contentResource &&
    container
  ) {
    // player is defined and has been updated
    try {
      const { api: playerApi, element: videoDomElement } = await initPlayerWithConfig(container);
      const contentResource: ContentResource = {
        ...{ somMarketing: somMarketingDefault },
        ...state.contentResource,
      } as ContentResource;
      playerApi.addContent(contentResource);
      playerApi.addTrackingProvider({
        track(eventName, payload) {
          trackMedia(eventName, payload);
        }
      });
      playerApi.on(PlayerEvent.DESTROYED, () => dispatch({ type: 'reset' }));
      playerApi.on(PlayerEvent.PLAYING, () => {
        const shadow = container.querySelector('[data-oasis-id="shadow-root-container"]')?.shadowRoot
        const videoElement = shadow?.querySelector('video')
        videoElement?.setAttribute('data-io-video-title', state.contentResource?.title as string)
        videoElement?.setAttribute('data-io-video-id', state.contentResource?.id as string)
      });
      const updatePosition = createUpdatePosition(contentResource);
      playerApi.on(PlayerEvent.POSITION_CHANGE, ({ position }) => updatePosition(position));
      playerApi.setPlaylistIndex(0);
      playerApi.play().catch(reason => console.debug('playerApi.play', contentResource.id, reason));
      currentPlayerInstance = {
        playerApi,
        videoDomElement,
      };
    } catch (err) {
      console.error(err);
    }
  }

  // if (
  //   prevState.activePlayer === state.activePlayer &&
  //   prevState.isInView !== state.isInView &&
  //   currentPlayerInstance.videoDomElement &&
  //   container
  // ) {
  //   // view needs update and videoDomElement exists
  //   const detachedRoot = detachNode(currentPlayerInstance.videoDomElement);
  //   if (detachedRoot) {
  //     container.appendChild(detachedRoot);
  //   }
  // }

  return currentPlayerInstance;
};
